import {ContentSearchHit} from "../../models/contentSearchHit";
import {ContentTweet, DataType} from "../../models/contentApi.model";
import {getDateFromApiString} from "../../util/api";
import React, {SyntheticEvent} from "react";
import {ChatAltIcon, NewspaperIcon, RssIcon} from "@heroicons/react/outline";
const { default: RedditIcon } =
    require("../../images/social/reddit-orange-icon.svg") as {
        default: string;
    };
const { default: DefaultUserIcon } =
    require("../../images/user-128.svg") as {
        default: string;
    };

const { default: TwitterIcon } =
    require("../../images/social/twitterx-icon.svg") as {
        default: string;
    };


const replaceImageDefault = (e: SyntheticEvent<HTMLImageElement>) => {
    const img = e.target as HTMLImageElement;
    img.onerror = () => {};
    img.src = DefaultUserIcon;
};

const getDataIcon = (typeId: number) => {
    const defaultIcon = (
        <div className={"w-12 h-12 rounded-full bg-green-600 flex items-center pointer-events-none pl-2"} >
            <ChatAltIcon className="h-8 w-8 text-white"
                         aria-hidden="true"  />
        </div>
    )
    // forums
    if (typeId === DataType.Discussions) {
        return defaultIcon
    }
    if (typeId === DataType.Blogs) {
        return (
            <div className={"w-12 h-12 rounded-full bg-amber-600 flex items-center pointer-events-none pl-2"} >
                <RssIcon className="h-8 w-8 text-white"
                         aria-hidden="true"  />
            </div>
        )
    }
    if (typeId === DataType.News) {
        return (
            <div className={"w-12 h-12 rounded-full bg-violet-700 flex items-center pointer-events-none pl-2"} >
                <NewspaperIcon className="h-8 w-8 text-white"
                               aria-hidden="true"  />
            </div>
        )
    }
    return defaultIcon;
}

const getDataColor = (typeId: number) => {
    const defaultColor = "bg-green-400"
    if (typeId === DataType.Discussions) {
        return defaultColor
    }
    if (typeId === DataType.Blogs) {
        return "bg-amber-400"
    }
    if (typeId === DataType.News) {
        return "bg-violet-400"
    }
    return defaultColor
}


const getDomain = (url: string): string | null => {
    if (!url) {
        return null;
    }
    const parsedUrl = new URL(url)
    return parsedUrl?.hostname ?? null;
}


const SearchHit = ({ hit, wrapperClass }: {hit: ContentSearchHit, wrapperClass: string}) => {
    const postTime = getDateFromApiString(hit.created_at).toLocal();
    if (hit.content_type === DataType.RedditPost) {
        const userData = hit.author_data[0]
        let isAuthorMissing = false
        if (!userData) {
            isAuthorMissing = true
            console.log(hit)
        }
        const userName = userData ? userData.unique_id : 'Unknown';
        return (
            <div className={wrapperClass}>
                <div className={"flex pt-2 pb-2"}>
                    <div className={"flex-none pr-2 pt-1"}>
                        { isAuthorMissing ? (
                            <img
                                width={48}
                                height={48}
                                src={DefaultUserIcon}
                                className={"rounded-full bg-orange-300"}
                            />
                        ) : (
                            <a href={`https://reddit.com/u/${userName}`} target={"_blank"}>
                                <img
                                    width={48}
                                    height={48}
                                    src={DefaultUserIcon}
                                    className={"rounded-full bg-orange-300"}
                                />
                            </a>
                        )
                        }
                    </div>
                    <div className={"flex-initial pr-4"}>
                        <div className={"pt-2"}>
                            { isAuthorMissing ? (
                                <div className={"text-lg font-bold"}>{userName}</div>
                            ) : (
                                <a
                                    className={"text-blue-700 hover:text-blue-900"}
                                    href={`https://reddit.com/u/${userName}`}
                                    target={"_blank"}
                                >
                                    {userName}
                                </a>
                            )
                            }
                            <div className={"text-gray-600 text-sm pl-1"}>
                                r/{hit.subreddit}
                            </div>
                        </div>
                    </div>
                    <div className={"flex-initial text-gray-400 text-sm pt-1 pl-1 lg:pl-4"}>
                        <div className={"pb-1"}>{postTime.toFormat("h:mm a")}</div>
                        <div>
                            {postTime.monthShort} {postTime.day}, {postTime.year}
                        </div>
                    </div>
                    <div className={"flex-grow pt-2"}>
                        <a
                            className={"inline-block float-right"}
                            target={"_blank"}
                            href={`${hit.url}`}
                        >
                            <img width={45} height={45} src={RedditIcon}/>
                        </a>
                    </div>
                </div>
                { hit.title ? (<div className={"p-2 lg:p-6 font-bold text-lg"}>{hit.title}</div>) : null }
                <div className={"p-2 lg:p-6"}>{hit.text}</div>
            </div>)
    }
    if (hit.content_type === DataType.Tweet) {
        const userData = hit.author_data[0]
        const userImagePath = hit.profile_image_url;
        const handle = userData.handle;
        const userName = userData.display_name;
        const tweetTime = getDateFromApiString(hit.created_at).toLocal();
        return (
            <div className={wrapperClass}>
                <div className={"flex pt-2 pb-2"}>
                    <div className={"flex-none pr-2 pt-1"}>
                        <a href={`https://twitter.com/${handle}`} target={"_blank"}>
                            {
                                userImagePath ? (
                                    <img
                                        width={48}
                                        height={48}
                                        onError={replaceImageDefault}
                                        src={userImagePath}
                                        className={"rounded-full bg-blue-300"}
                                    />
                                ) : (
                                    <img
                                        width={48}
                                        height={48}
                                        src={DefaultUserIcon}
                                        className={"rounded-full bg-blue-300"}
                                    />
                                )
                            }
                        </a>
                    </div>
                    <div className={"flex-initial pr-4"}>
                        <div className={"text-lg font-semibold"}>{userName}</div>
                        <div>
                            <a
                                className={"text-blue-700 hover:text-blue-900"}
                                href={`https://twitter.com/${handle}`}
                                target={"_blank"}
                            >
                                @{handle}
                            </a>
                        </div>
                    </div>
                    <div className={"flex-initial text-gray-400 text-sm pt-1 pl-1 lg:pl-4"}>
                        <div className={"pb-1"}>{tweetTime.toFormat("h:mm a")}</div>
                        <div>
                            {tweetTime.monthShort} {tweetTime.day}, {tweetTime.year}
                        </div>
                    </div>
                    <div className={"flex-grow pt-2"}>
                        <a
                            className={"inline-block float-right"}
                            target={"_blank"}
                            href={`https://twitter.com/i/web/status/${hit.vendor_id}`}
                        >
                            <img width={40} height={40} src={TwitterIcon}/>
                        </a>
                    </div>
                </div>
                <div className={"p-2 lg:p-6"}>{hit.text}</div>
            </div>
        );
    }
    if (hit.content_type === DataType.Discussions || hit.content_type === DataType.Blogs || hit.content_type === DataType.News) {
        const hasDomain = !!getDomain(hit.url!);
        return (
            <div className={wrapperClass}>
                <div className={"flex pt-2 pb-2"}>
                    <div className={"flex-none pr-2 pt-1"}>
                        <img
                            width={48}
                            height={48}
                            src={DefaultUserIcon}
                            className={"rounded-full " + getDataColor(hit.content_type)}
                        />
                    </div>
                    <div className={"flex-initial pr-4"}>
                        { hasDomain ? (
                            <div className={"text-lg font-semibold pt-3"}>{getDomain(hit.url!)}</div>
                        ) : null}
                    </div>
                    <div className={"flex-initial text-gray-400 text-sm pt-1 pl-1 lg:pl-4"}>
                        <div className={"pb-1"}>{postTime.toFormat("h:mm a")}</div>
                        <div>
                            {postTime.monthShort} {postTime.day}, {postTime.year}
                        </div>
                    </div>
                    <div className={"flex-grow pt-2"}>
                        <a
                            className={"inline-block float-right"}
                            target={"_blank"}
                            href={`${hit.url}`}
                        >
                            {getDataIcon(hit.content_type)}
                        </a>
                    </div>
                </div>
                { hit.title ? (<div className={"p-2 lg:p-6 font-bold text-lg"}>{hit.title}</div>) : null }
                <div className={"p-2 lg:p-6"}>{hit.text}</div>
            </div>
        );
    }
    return null;
}

export default SearchHit;
