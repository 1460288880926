import React from "react";
import {getDateFromApiString} from "../../util/api";
import {ContentArxivPaper} from "../../models/contentApi.model";
import {AcademicCapIcon, RssIcon} from "@heroicons/react/outline";
import OpenAiResponseText from "../openai/OpenAiResponseText";
const { default: DefaultUserIcon } =
    require("../../images/user-128.svg") as {
        default: string;
    };

type ArxivProps = {
    contentData: ContentArxivPaper;
    wrapperClass: string;
};

const ContentArxivPaperItem = ({contentData, wrapperClass}: ArxivProps) => {
    const authors = contentData.author?.authors
    let isAuthorMissing = false
    const authorNames = []
    if (!authors) {
        isAuthorMissing = true
    } else {
        authors.forEach(author => {
            authorNames.push(author.full_name);
        });
    }
    const postTime = getDateFromApiString(contentData.created_at).toLocal();
    const updatedTime = getDateFromApiString(contentData.arxiv_updated).toLocal();
    return (
        <div className={wrapperClass}>
            <div className={"flex pt-2 pb-2"}>
                <div className={"flex-none pr-2 pt-1"}>
                    <img
                        width={48}
                        height={48}
                        src={DefaultUserIcon}
                        className={"rounded-full bg-indigo-300"}
                    />
                </div>
                <div className={"flex-initial text-gray-400 text-sm pt-1 pl-1 lg:pl-4"}>
                    <div>Published:</div>
                    <div className={"pb-1"}>{postTime.toFormat("h:mm a")}</div>
                    <div>
                        {postTime.monthShort} {postTime.day}, {postTime.year}
                    </div>
                </div>
                <div className={"flex-initial text-gray-400 text-sm pt-1 pl-1 lg:pl-4"}>
                    <div>Updated:</div>
                    <div className={"pb-1"}>{updatedTime.toFormat("h:mm a")}</div>
                    <div>
                        {updatedTime.monthShort} {updatedTime.day}, {updatedTime.year}
                    </div>
                </div>
                <div className={"flex-grow pt-2"}>
                    <a
                        className={"inline-block float-right"}
                        target={"_blank"}
                        href={`https://arxiv.org/abs/${contentData.arxiv_id}`}
                    >
                        <div
                            className={"w-12 h-12 rounded-full bg-indigo-600 flex items-center pointer-events-none pl-2"}>
                            <AcademicCapIcon className="h-8 w-8 text-white"
                                     aria-hidden="true"/>
                        </div>
                    </a>
                </div>
            </div>
            <div>
                <div className={"pr-4"}>
                    <div className={"pt-2 pl-6"}>
                        {isAuthorMissing ? (
                            <div className={"text-gray-400 text-xs"}>Authors: {'unknown'}</div>
                        ) : (
                            <div className={"text-gray-400 text-xs"}>Authors: {authorNames.join(" | ")}</div>
                        )
                        }
                    </div>
                </div>
            </div>
            {contentData.title ? (<div className={"p-2 lg:p-6 font-bold text-lg"}>{contentData.title}</div>) : null}
            <div className={"pl-2 pt-2 text-md"}>Abstract summary:</div>
            <div className={"p-2 text-sm"}>
                <OpenAiResponseText text={contentData.abstract_summary} className={"p-1"} elementClassName={""} />
            </div>
            <div className={"pl-2 pt-2 text-md"}>Points of interest:</div>
            <div className={"p-2 text-sm"}>
                <OpenAiResponseText text={contentData.arxiv_subject_hit_summary} className={"p-1"} elementClassName={""} />
            </div>
        </div>
    );
};

export default ContentArxivPaperItem;
