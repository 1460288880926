import React, {ChangeEventHandler, Fragment, useEffect, useState,} from "react";
import TileRounded from "../layout/TileRounded";
import TileBgNone from "../layout/TileBgNone";
import Spinner from "../animation/Spinner";
import "react-day-picker/dist/style.css";
import {DashboardViewProps} from "../dashboards/dashboardTypes";
import {useGetContextResultByDate, useGetRecentContextResults} from "./getContext";
import {ContextResult} from "../../models/context.model";
import {ErrorType, getDateFromApiString, SummaryType} from "../../util/api";
import OpenAiResponseText from "../openai/OpenAiResponseText";
import {format, isValid, parse} from "date-fns";
import {DayPicker, SelectSingleEventHandler} from "react-day-picker";
import {ExclamationCircleIcon} from "@heroicons/react/solid";
import {Popover, Transition} from "@headlessui/react";
import {CalendarIcon} from "@heroicons/react/outline";

export type SummaryOption = {
    summaryIndex: number;
    summaryContextIndex: number;
}

const SummaryStream = ({ isSubjectLoading, dashboardId, subjectData }: DashboardViewProps) => {
    const [summaries, setSummaries] = useState<ContextResult[]>([]);
    const [dateSummaries, setDateSummaries] = useState<ContextResult[]>([]);
    const [isSummaryLoaded, setIsSummaryLoaded] = useState<boolean>(false);
    const [summarySelections, setSummarySelections] = useState<SummaryOption[]>([]);
    const [dateApi, setDateApi] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [inputDateValue, setInputDateValue] = useState<string>("");
    const [isDateError, setIsDateError] = useState<boolean>(false);


    const getContextInputLabel = (input: string) => {
        return input.charAt(0).toUpperCase() + input.slice(1);
    }

    const getSummarySelectedOption = (index: number) => {
        const summarySelection = summarySelections ? summarySelections.find(item => item.summaryIndex === index) : null;
        if (!summarySelection) {
            return 0
        }
        return summarySelection.summaryContextIndex
    }

    const onSummarySelectedOptionChange = (summaryIndex: number, inputIndex: number | string | undefined | null) => {
        let inputIndexNumber: number
        if (inputIndex === null || inputIndex === undefined) {
            inputIndexNumber = 0
        } else {
            inputIndexNumber = typeof inputIndex === 'string' ? parseInt(inputIndex) : inputIndex
        }
        const summarySelection = summarySelections.find(item => item.summaryIndex === summaryIndex)
        let summarySelected: SummaryOption
        if (!summarySelection) {
            summarySelected = {
                summaryIndex: summaryIndex,
                summaryContextIndex: inputIndexNumber,
            }
            summarySelections.push(summarySelected)
        } else {
            summarySelection.summaryIndex = summaryIndex
            summarySelection.summaryContextIndex = inputIndexNumber
        }
        setSummarySelections([...summarySelections])
    }

    const createChangeHandler = (index) => (e) => onSummarySelectedOptionChange(index, e.target.value)

    const {
        isLoading,
        data,
        error,
        execute,
    } = useGetRecentContextResults();

    const {
        isLoading: isDateLoading,
        data: dateData,
        error: dateError,
        execute: dateExecute,
    } = useGetContextResultByDate();

    useEffect( () => {
        if (!isSubjectLoading) {
            execute(dashboardId).then((response) => {
                setSummaries(response?.summaries ?? [])
                const newSummarySelections: SummaryOption[] = []
                let index = 0
                if (response?.summaries) {
                    for (const summary of response.summaries) {
                        newSummarySelections.push({
                            summaryIndex: index,
                            summaryContextIndex: 0
                        })
                        index++
                    }
                }
                setSummarySelections(newSummarySelections)
                setIsSummaryLoaded(true)
            })
        }
    }, [dashboardId, isSubjectLoading])


    useEffect( () => {
        if (dateApi === null) {
            return;
        }
        if (!isSubjectLoading) {
            setIsSummaryLoaded(false)
            dateExecute(dashboardId, dateApi).then((response) => {
                setDateSummaries(response?.summaries ?? [])
                const newSummarySelections: SummaryOption[] = []
                let index = 0
                if (response?.summaries) {
                    for (const summary of response.summaries) {
                        newSummarySelections.push({
                            summaryIndex: index,
                            summaryContextIndex: 0
                        })
                        index++
                    }
                }
                setSummarySelections(newSummarySelections)
                setIsSummaryLoaded(true)
            })
        }
    }, [dashboardId, dateApi, isSubjectLoading])


    const loadingJsx = (
        <div className={"flex items-center"}>
            <div className={"m-auto pt-16 pb-16"}>
                <Spinner width={200} height={200} />
            </div>
        </div>
    );

    const noSummariesJsx = (message: string) => {
        return (
            <div
                className={
                    "m-auto pt-16 pb-16 text-center text-xl text-semi-bold text-gray-400"
                }
            >
                {message}
            </div>
        );
    };

    const getSummaryRender = () => {
        if (!isSummaryLoaded || isSubjectLoading) {
            return loadingJsx;
        }
        if ((dateApi ? dateError : error) === ErrorType.NotFound) {
            return noSummariesJsx('no insights found')
        }
        if ((dateApi ? dateError : error)) {
            return noSummariesJsx('an error occurred while retrieving insights')
        }
        if (!(dateApi ? dateSummaries : summaries) || (dateApi ? dateSummaries : summaries).length === 0) {
            return noSummariesJsx('no insights found')
        }
        const summaryItems = []
        let index = 0;
        for (const summary of (dateApi ? dateSummaries : summaries)) {
            const startDate = getDateFromApiString(summary.start_date).toLocal()
            const endDate = getDateFromApiString(summary.end_date).toLocal()
            const startDateText = `${startDate.monthShort} ${startDate.day}, ${startDate.year} ${startDate.toFormat("h:mm a")}`
            const endDateText = `${endDate.monthShort} ${endDate.day}, ${endDate.year} ${endDate.toFormat("h:mm a")}`
            const contextFields = [];
            let fieldIndex = 0;
            if (summary.context_fields && summary.context_fields.length > 0) {
                for (const field of summary.context_fields) {
                    fieldIndex++
                    contextFields.push(
                        <div key={`field-${fieldIndex}`}>
                            <h1 className={"text-xl font-bold"}>{field.context_label}</h1>
                            <div>
                                <OpenAiResponseText text={field.context_output}  className={"p-1"}  elementClassName={""}/>
                            </div>
                        </div>
                    )
                }
            }
            const summaryInputOptions = []
            let summarySelectJsx = null
            let summaryTextJsx = null
            if (subjectData?.summaryContext === SummaryType.SUMMARY_CONTEXT_SENTIMENT_CHAIN || subjectData?.summaryContext === SummaryType.SUMMARY_SENTIMENT_CHAIN) {
                const selectedOption = getSummarySelectedOption(index)
                let inputIndex = 0
                for (const summaryItem of summary.context_outputs) {
                    summaryInputOptions.push(
                        <option key={summaryItem.input_type} value={inputIndex} >{getContextInputLabel(summaryItem.input_type)}</option>
                    )
                    inputIndex++
                }
                summarySelectJsx = (
                    <>
                        <div className={"text-center"}>
                            <label className={"text-gray-500 text-md"}>Sentiment</label>
                        </div>
                        <select
                            className={"mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md font-normal"}
                            onChange={createChangeHandler(index)}
                            defaultValue={selectedOption}
                        >
                            {summaryInputOptions}
                        </select>
                    </>
                )
                summaryTextJsx = (
                    <OpenAiResponseText text={summary.context_outputs[selectedOption].output}  className={"p-1"}  elementClassName={""}/>
                )
            } else {
                summaryTextJsx = (
                    <OpenAiResponseText text={summary.context_outputs[0].output}  className={"p-1"}  elementClassName={""}/>
                )
            }
            summaryItems.push(
                <div key={`summary-${index}`} className={"border-b-2 border-gray-100 pb-4 pt-2"}>
                    <div className={"pb-4 flex"}>
                        <div className={"flex-grow pt-2"}>
                            <h1 className={"text-xl font-bold "}>Insights</h1>
                            <div className={"text-gray-500 text-md pb-2 pt-2"}>
                                Using data from {startDateText} to {endDateText}
                            </div>
                        </div>
                        <div>
                            {summarySelectJsx}
                        </div>
                    </div>
                    <div>
                        {summaryTextJsx}
                    </div>
                    {contextFields}
                </div>
            )
            index++
        }
        return summaryItems
    }

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInputDateValue(e.currentTarget.value);
        if (e.currentTarget.value.length < 10) {
            setSelectedDate(undefined);
            return;
        }
        const date = parse(e.currentTarget.value, "y-MM-dd", new Date());
        if (isValid(date)) {
            setSelectedDate(date);
            setDateApi(e.currentTarget.value);
        } else {
            setIsDateError(true);
            setSelectedDate(undefined);
        }
    };

    const handleDaySelect = (
        closeCallBack: () => void
    ): SelectSingleEventHandler => {
        return (date, selectedDay, activeModifiers, e) => {
            setSelectedDate(date);
            if (date) {
                const dateString = format(date, "y-MM-dd");
                setInputDateValue(dateString);
                setIsDateError(false);
                setDateApi(dateString);
                closeCallBack();
            } else {
                setInputDateValue("");
            }
        };
    };

    const resetDate = () => {
        setInputDateValue("");
        setSelectedDate(undefined);
        setIsDateError(false);
        setDateApi(null);
    };

    const getCalendar = () => {
        let inputClass =
            "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md";
        if (isDateError) {
            inputClass =
                "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md";
        }
        return (
            <div className={"flex float-right"}>
                <div className={"flex-1 mt-1"}>
                    <label htmlFor="date" className="sr-only">
                        Enter Date in YYYY-MM-DD format
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                            type="text"
                            name="date"
                            id="date"
                            className={inputClass}
                            placeholder={format(new Date(), "y-MM-dd")}
                            value={inputDateValue}
                            onChange={handleInputChange}
                            aria-invalid="true"
                            aria-describedby={isDateError ? "email-error" : undefined}
                        />
                        {isDateError ? (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <ExclamationCircleIcon
                                    className="h-5 w-5 text-red-500"
                                    aria-hidden="true"
                                />
                            </div>
                        ) : null}
                    </div>
                    {isDateError ? (
                        <p className="mt-1 text-sm text-red-600 absolute" id="email-error">
                            Invalid date (YYYY-MM-DD format)
                        </p>
                    ) : null}
                </div>
                <div>
                    <Popover className="relative">
                        {({ open, close }) => (
                            <>
                                <Popover.Button
                                    className={`
                ${open ? "" : "text-opacity-90"}
                mb-0 pb-0 focus:outline-none group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                >
                                    <CalendarIcon
                                        className={
                                            "h-9 w-9 text-blue-600 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                        }
                                    />
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 -translate-x-full transform px-4 sm:px-0">
                                        <div className="overflow-hidden bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                            <DayPicker
                                                initialFocus={open}
                                                mode="single"
                                                defaultMonth={selectedDate}
                                                selected={selectedDate}
                                                onSelect={handleDaySelect(close)}
                                            />
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover>
                </div>
            </div>
        );
    };

    const getResetDateButton = () => {
        if (dateApi) {
            return (
                <button
                    type={"button"}
                    className="inline-flex items-center px-4 py-2  border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => resetDate()}
                >
                    Reset Date
                </button>
            )
        }
        return null
    }

    return (
        <TileBgNone>
            <div className={"flex pt-1 pb-5"}>
                <div className={"flex-initial pt-3"}>
                    <h2 className={"text-xl"}>Insights</h2>
                </div>
                <div className={"flex-grow"}>{getCalendar()}</div>
            </div>
            <TileRounded
                classArray={["max-h-[950px]", "overflow-auto"]}
                tileType={"inner"}
            >
                <div className={"min-h-[250px] p-2"}>
                    {getResetDateButton()}
                    {getSummaryRender()}
                </div>
            </TileRounded>
        </TileBgNone>
    );
};

export default SummaryStream;
